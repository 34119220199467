
























































































































import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import '../scss/AboutProjectInfo.scss';
import { namespace } from 'vuex-class';
import { IMemberRole, IProject, IProjectState } from '@store/modules/project/Interfaces';
import { AboutProjectActions } from '@store/modules/about-project/Types';
import { IStatus } from '@store/modules/user-projects/Interfaces';
import { IJuristicEntity } from "@store/modules/about-project/Interfaces";

const NSProject = namespace('storeProject');
const NSAboutProject = namespace('storeAboutProject');
const NSUserProjects = namespace('storeUserProjects');

@Component({
    name: 'AboutProjectInfo',
    components: {
        BasePreloader: () => import('@components/BasePreloader/BasePreloader.vue'),
        PopupRegistryData: () => import('@components/popups/PopupRegistryData.vue'),
        PopupConfirmation: () => import('@components/popups/PopupConfirmation.vue'),
    }
})

export default class AboutProjectInfo extends Vue {
    $refs!: { registryPopup: HTMLFormElement }

    @NSProject.State((state: IProjectState) => state.memberRolesJuristic) memberRolesJuristic!: IMemberRole[];
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSUserProjects.Getter('projectStatuses') projectStatuses!: IStatus[];
    @NSAboutProject.Getter('juristicEntities') juristicEntities!: IJuristicEntity[];
    @NSAboutProject.Action(AboutProjectActions.A_CHANGE_PROJECT_TITLE_OR_ADDRESS) changeProjectData!: (payload: any) => Promise<void>;
    @NSAboutProject.Action(AboutProjectActions.A_CREATE_MEMBER_JURISTIC) createNewMemberJuristic!: (payload) => Promise<void>
    @NSAboutProject.Action(AboutProjectActions.A_GET_JURISTIC_MEMBERS_ABOUT_PROJECT) getJuristicMembers!: (id: string | number) => Promise<void>;

    registryPopupOpen: boolean = false;
    popupConfirmationText: string = '';
    popupConfirmationOpen: boolean = false;
    popupConfirmationOpenDuration: number = 3500;
    timeOutForAddJuristicMember: number = 1250;
    wasChanged: boolean = false;
    updatingJuristicMemberData: any = null;
    juristicMembersCorrelation = [
        {
            // theirTitle: 'Застройщик (?)',
            theirKey: 'DeveloperAndTechnicalCustomerOrganizationInfo',
            ourRole: 'Застройщик (технический заказчик, эксплуатирующая организация или региональный оператор)',
        },{
            // theirTitle: 'ИП и (или) юридическое лицо, подготовившее проектную документацию (исполнители работ)',
            theirKey: 'PlannerOrganizationInfo',
            ourRole: 'Проектировщик',
        },{
            // theirTitle: 'Застройщик (?)',
            theirKey: 'DeveloperOrganizationInfo',
            ourRole: 'Технический застройщик',
        },{
            // theirTitle: 'DeveloperAndTechnicalCustomerOrganizationInfo',
            theirKey: 'TechnicalCustomerOrganizationInfo',
            ourRole: 'Инвестор/заказчик',
        },
    ];
    juristicMembersCorrelationIndex: number = 0;

    get addressAsParam() {
        return this.projectData.address.replace(/[.,:;|\\_]/g, '').replaceAll(' ', '%20');
    }

    get projectStatusId() {
        const statusObjects = this.projectStatuses.filter(item => item.title === this.projectData.status);
        if (statusObjects[0]) {
            return statusObjects[0].id;
        }
        return null;
    }

    openRegistryPopup() {
        this.$refs.registryPopup.openPopup();
    }

    getDate(data) {
        const date = new Date(data);
        let day: string | number = date.getDate();
        let month: string | number = date.getMonth() + 1;
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return `${day}.${month}.${date.getFullYear()}`;
    }

    responsiblePersons(item) {
        const added: string[] = [];
        const output: any[] = [];
        item.responsiblePersons.forEach((responsiblePerson) => {
            if (!added.includes(`${responsiblePerson.id}${responsiblePerson.position}`)) {
                added.push(`${responsiblePerson.id}${responsiblePerson.position}`);
                output.push(responsiblePerson);
            }
        });
        return output;
    }

    checkUpdateData(data) {
        this.updatingJuristicMemberData = data;
        this.updateProjectInfo(this.updatingJuristicMemberData)
            .then(() => {
                this.checkJuristicPersonIncludes(
                    this.updatingJuristicMemberData,
                    this.juristicMembersCorrelation[this.juristicMembersCorrelationIndex]
                )
                    .then(() => {
                        setTimeout(() => {
                            this.getJuristicMembers(this.projectData.id);
                        }, this.timeOutForAddJuristicMember + 1500);
                    });
            });
    }

    async updateProjectInfo(data) {
        const titleCondition = this.projectData.projectName !== data.ExpertiseObjectName;
        const addressCondition = this.projectData.address !== data.ExpertiseObjectAddress;
        let payload = {};
        if (titleCondition && addressCondition) {
            payload = {
                id: this.projectData.id,
                title: data.ExpertiseObjectName,
                address: data.ExpertiseObjectAddress,
                projectStatusId: this.projectStatusId,
            };
        } else if (titleCondition) {
            payload = {
                id: this.projectData.id,
                title: data.ExpertiseObjectName,
                projectStatusId: this.projectStatusId,
            };
        } else if (addressCondition) {
            payload = {
                id: this.projectData.id,
                address: data.ExpertiseObjectAddress,
                projectStatusId: this.projectStatusId,
            };
        }
        if (titleCondition || addressCondition) {
            setTimeout(() => {
                this.changeProjectData(payload);
            }, 200);
        }
    }

    async checkJuristicPersonIncludes(data, item) {
        if (
            data[item.theirKey] &&
            !this.juristicEntities.filter((entity: IJuristicEntity) => entity.title === data[item.theirKey]).length
        ) {
            const personRoles = this.memberRolesJuristic.filter(role => role.value === item.ourRole);
            if (personRoles.length) {
                const matches = data[item.theirKey].toLowerCase().match(/инн:? ?(\d{10})/);
                let payload = {
                    appId: this.projectData.id,
                    name: data[item.theirKey].toLowerCase(),
                    fullName: data[item.theirKey].toLowerCase(),
                    inn: matches[1] ?? '',
                    roleId: personRoles[0].uuid,
                    phones: [],
                }
                await this.createNewMemberJuristic(payload).then(() => {
                    if (this.juristicMembersCorrelationIndex === this.juristicMembersCorrelation.length - 1) {
                        if (!this.wasChanged) {
                            this.showInformationPopup('Данные о проекте соответствуют выбранной из реестра записи.');
                        }
                        this.wasChanged = false;
                    } else {
                        setTimeout(() => {
                            this.juristicMembersCorrelationIndex += 1;
                            this.checkJuristicPersonIncludes(
                                this.updatingJuristicMemberData,
                                this.juristicMembersCorrelation[this.juristicMembersCorrelationIndex]
                            );
                        }, this.timeOutForAddJuristicMember);
                    }
                });
                this.showInformationPopup('Данные о проекте успешно изменены с учетом выбора записи из реестра.');
                this.wasChanged = true;
            }
        }
    }

    showInformationPopup(text) {
        this.popupConfirmationText = text;
        this.popupConfirmationOpen = true;
        setTimeout(() => {
            this.popupConfirmationOpen = false;
        }, this.popupConfirmationOpenDuration);
    }
}
